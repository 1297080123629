<template>
	<div class="h3 text-center">
		<v-toolbar-title id="title"><v-icon id="ic" v-if="icon" :class="icon"></v-icon> {{ main }}
		</v-toolbar-title>
		<small>{{ sub }}</small>
		<hr class="orange">
	</div>
</template>

<script>
	export default {
		name: 'PageTitle',
		props: ['icon', 'main', 'sub']
	};
</script>

<style lang="css" scoped>
	@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Lato:wght@100&family=Poppins:ital,wght@0,400;1,100;1,400');
	#title {
		font-family: Poppins, sans-serif ;
		font-weight: bold;
	}
	#ic {
		color: #000000;
	}
</style>
