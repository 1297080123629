<template>
	<v-container>
		<PageTitle icon="mdi mdi-account-wrench" main="Ordem de Serviço"/>
		<v-card v-if="qrCode">
			<v-card-text>
				<div class="d-flex justify-center align-center" style="height: 100vh;">
					<v-col cols="12" sm="6" md="4">
						<v-subheader>Qr-Code para conectar Whatsapp</v-subheader>
						<div class="qr-image text-center"><v-img :src="qrCode" aspect-ratio="1" width="50%"></v-img></div>

					</v-col>
				</div>
			</v-card-text>
		</v-card>
		<v-alert v-if="aguarda" class="orange--text">{{ aguarda }}</v-alert>
		<v-flex v-if="conectado" class="success--text">Whatsapp {{ conectado }} </v-flex>
		<v-tabs v-model="tab" background-color="grey lighten-3">
			<v-tab>Pendentes</v-tab>
			<v-tab>Finalizados</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-card flat>
					<v-card-text>
						<v-data-table ref="myTablePendente" :headers="table" :items="pendentes" item-key="protocolo" :expanded.sync="expandedItems">
							<template v-slot:[`item.data`]="{ item }">
								{{ formatoData(item.data) }}<br>
								<small>up: {{ formatoData(item.atualizacao) }}</small>
							</template>

							<template v-slot:[`item.actions`]="{ item }">
								<v-btn small color="orange" @click="toggleExpansion(item)" dark>
									{{ item.status }}
									<v-icon>
										{{ expandedItems.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
									</v-icon>
								</v-btn>
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<v-container>
										<v-card>
											<v-card-title>Imagens/Vídeos</v-card-title>
											<v-card-actions>
												<v-btn x-small color="success" @click="finalizar(item)">
													Finalizar Protocolo {{item.protocolo}}
												</v-btn>
											</v-card-actions>
											<v-card-text v-for="foto in item.fotos" :key="foto">
												<v-col style="border: #333 solid 1.5px;">
													<img :src="foto" alt="Foto" width="80%"/>
													<v-col><v-btn x-small color="error"><v-icon small outlined>mdi-delete</v-icon>excluir imagem</v-btn></v-col>
												</v-col>
											</v-card-text>
											<v-card-text v-for="video in item.videos" :key="video">
												<v-col style="border: #333 solid 1.5px;">
													<video width="100%" controls>
														<source :src="video" type="video/mp4">
															Seu navegador não suporta vídeos.
														</video>
														<v-col><v-btn x-small color="error"><v-icon small outlined>mdi-delete</v-icon>excluir imagem</v-btn></v-col>
													</v-col>
												</v-card-text>
											</v-card>
										</v-container>
									</td>
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<v-data-table ref="myTableFinalizados" :headers="table" :items="finalizados" item-key="protocolo">
								<template v-slot:[`item.data`]="{ item }">
									{{ formatoData(item.data) }}<br>
									<small>up: {{ formatoData(item.atualizacao) }}</small>
								</template>
								<template v-slot:[`item.actions`]="{ item }">
									<v-btn small text color="error" dark @click="item
									">FINALIZADO</v-btn>
								</template>

								<!-- <template v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<v-container>
											<v-card>
												<v-card-title>Imagens/Vídeos</v-card-title>
												<v-card-actions>
													<v-btn x-small color="success" @click="finalizar(item)">
														Finalizar Protocolo {{item.protocolo}}
													</v-btn>
												</v-card-actions>
												<v-card-text v-for="foto in item.fotos" :key="foto">
													<v-col style="border: #333 solid 1.5px;">
														<img :src="foto" alt="Foto" width="80%"/>
														<v-col><v-btn x-small color="error"><v-icon small outlined>mdi-delete</v-icon>excluir imagem</v-btn></v-col>
													</v-col>
												</v-card-text>
												<v-card-text v-for="video in item.videos" :key="video">
													<v-col style="border: #333 solid 1.5px;">
														<video width="100%" controls>
															<source :src="video" type="video/mp4">
																Seu navegador não suporta vídeos.
															</video>
															<v-col><v-btn x-small color="error"><v-icon small outlined>mdi-delete</v-icon>excluir imagem</v-btn></v-col>
														</v-col>
													</v-card-text>
												</v-card>
											</v-container>
										</td>
									</template> -->
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
				<v-col>

				</v-col>
			</v-container>
		</template>

		<script>
			import PageTitle from "@/templates/PageTitle"
			import axios from 'axios'
			import { baseApiUrl, showError } from '@/global'
			import moment from 'moment'
			export default {
				components: { PageTitle },
				name: 'OrdemServico',
				data () {
					return {
						busca:'',
						tab:null,
						qrCode:'',
						aguarda:'Verificando conexão com Whatsapp ...',
						loading:true,
						conectado:'',
						pendentes:[],
						finalizados:[],
						expandedItems: [],
						table:[
							{text:'Nome', value:'nome'},
							{text:'Contato', value:'contato'},
							{text:'Endereço', value:'endereco'},
							{text:'Habitado', value:'habitado'},
							{text:'Protocolo', value:'protocolo'},
							{text:'Data', value:'data'},
							{text:'Actions', value:'actions'}
							]
					}
				},
				methods: {
					loadOrdens() {
						axios.get(`${baseApiUrl}/ordem_servico`)
						.then(res => {
							
							this.pendentes = res.data.pendentes.map(o => {
								return {
									nome:o.nome,
									sessionid:o.sessionid,
									contato:o.sessionid.split('55')[1].split('@')[0],
									protocolo:o.protocolo,
									endereco:o.endereco,
									habitado:o.habitado,
									status:o.status,
									fotos:o.fotos.map(foto => `${baseApiUrl}/${foto.url_foto.split('D:\\apps\\dengue\\backend\\')[1]}`),
									videos:o.videos.map(video => `${baseApiUrl}/${video.url_video.split('D:\\apps\\dengue\\backend\\')[1]}`),
									// fotos:o.fotos.map(foto => `${baseApiUrl}/${foto.url_foto.split('/home/disquedengue.saeso.app.br/public_html/bcknd/')[1]}`),
									// videos:o.videos.map(video => `${baseApiUrl}/${video.url_video.split('/home/disquedengue.saeso.app.br/public_html/bcknd/')[1]}`),
									data:o.createdAt,
									atualizacao:o.updatedAt
								}
							})
							
							this.finalizados = res.data.finalizados.map(o => {
								return {
									nome:o.nome,
									sessionid:o.sessionid,
									contato:o.sessionid.split('55')[1].split('@')[0],
									protocolo:o.protocolo,
									endereco:o.endereco,
									habitado:o.habitado,
									status:o.status,
									// fotos:o.fotos.map(foto => `${baseApiUrl}/${foto.url_foto.split('D:\\apps\\dengue\\backend\\')[1]}`),
									// videos:o.videos.map(video => `${baseApiUrl}/${video.url_video.split('D:\\apps\\dengue\\backend\\')[1]}`),
									data:o.createdAt,
									atualizacao:o.updatedAt
								}
							})
						})
						.catch(showError)


					},
					finalizar(item) {
						this.toggleExpansion(item)
						const obj = { status:'FINALIZADO', sessionid: item.sessionid, protocolo:item.protocolo}
						axios.post(`${baseApiUrl}/ordem_servico`, obj).then(() => {
							this.$toasted.global.defaultSuccess();
							this.loadOrdens()

						})
						.catch(showError)
					},
					formatoData(data){
						return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")
					},
					toggleExpansion(item) {
						const index = this.expandedItems.indexOf(item);
						if (index > -1) {
							this.expandedItems.splice(index, 1);
						} else {
							this.expandedItems.push(item);
						}
					},
					loadQrCode() {
						this.loading = true; 
						this.aguarda = 'Verificando conexão com Whatsapp ...';
						axios.get(`${baseApiUrl}/qr-code`)
						.then(res => {
							this.loading = false; 
							if(res.data === 'Aguardando o QR code ser gerado...') {
								this.aguarda = res.data;
								this.conectado = '';
								this.qrCode = '';  
							} else if(res.data === 'Já está conectado!'){
								this.conectado = res.data;
								this.aguarda = '';
								this.qrCode = '';
							} else {
								this.qrCode = res.data;
								this.conectado = '';
								this.aguarda = '';
							}
						})
						.catch(error => {
							this.loading = false; 
							showError(error);
						}).finally(() => {
							setTimeout(this.loadQrCode, 10000); 
						});
					}
				},
				watch: {
					conectado(newValue) {
						if (newValue === 'Aguardando o QR code ser gerado...') {
							this.gerarQRCode();
						}
					}
				},
				mounted() {
					this.loadQrCode()
					this.loadOrdens()
				}
			}
		</script>

		<style lang="css" scoped>
			.qr-image {
				width: 500px; /* ou outra largura conforme necessário */
				height: 500px;
				display: block; /* para remover qualquer espaço extra abaixo da imagem */
			}
		</style>