<template>
	<v-app class="login">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4>
						<v-card color="transparent" outlined dark>
							<v-card-text>
								<form ref="form" @submit.prevent="login()">
									<v-text-field
									v-model="usuario.email"
									name="usuario"
									label="usuario"
									type="text"
									placeholder="usuario"
									></v-text-field>

									<v-text-field
									v-model="usuario.senha"
									name="senha"
									label="senha"
									type="password"
									placeholder="senha"
									></v-text-field>
									<v-btn type="submit" class="mt-4" color="success" value="log in">Entrar</v-btn>
								</form>
							</v-card-text>
						</v-card>

					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError, userKey } from '@/global'
	export default {
		name: 'AuthLogin',

		data () {
			return {
				usuario:{},
			}
		},
		methods: {
			login() {
				axios.post(`${baseApiUrl}/signin`, this.usuario)
				.then(res => {
					this.$store.commit('setUser', res.data)
					localStorage.setItem(userKey, JSON.stringify(res.data))
					this.$router.push({ path: '/' })

				})
				.catch(showError)
			},
		},
	}
</script>

<style lang="css" scoped>
	.login {
		background: url('@/img/bglogindengue.png') no-repeat center;
		background-size: 100%;
		height: 100%;
	}
</style>