import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import OrdemServico from '@/components/ordens/OrdemServico'
import Usuarios from '@/components/usuarios/Usuarios'
import AuthLogin from '@/components/auth/AuthLogin'

import { userKey } from '@/global'

Vue.use(VueRouter)

const routes = [{
	name: 'auth',
	path:'/auth',
	component: AuthLogin
},{
	name:'home',
	path:'/',
	component: Home
},{
	name:'usuarios',
	path:'/usuarios',
	component: Usuarios
},{
	name:'ordem_servico',
	path:'/ordem_servico',
	component: OrdemServico
}]


const router = new VueRouter({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {
	const json = localStorage.getItem(userKey)
	if(to.matched.some(record => record.meta.requiresAdmin)) {
		const user = JSON.parse(json)
		user  ? next() : next({ path: '/'})
	} else {
		next()
	}
})
export default router