<template>
	<v-main>
		<router-view/>	
	</v-main>
</template>

<script>
	export default {
		name: 'ContentTemplate',
	};
</script>

<style lang="css" scoped>
</style>
