<template>
	<div>
		<v-app-bar app color="info " dark>
			<v-app-bar-nav-icon @click.stop="drawer=!drawer"></v-app-bar-nav-icon>
			<v-toolbar-title class="titulo">

			</v-toolbar-title>
			<v-spacer></v-spacer>
			<!-- <Notification class="mr-5"/> -->
			<UserDropdown v-if="!hideUserDropdown"/>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app color="#015DD6" dark>
			<v-toolbar-title class="mt-2 text-center">
				<img src="@/img/logodengue.png" alt="Saeso Pesquisa" width="60%">
			</v-toolbar-title>
			<v-list dense>
				<v-list-item v-for="(item,i) in items" :key="i">
					<v-list-item :to="item.link" v-if="!item.sublinks">
						<v-list-item-icon>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-group  no-action v-else>
						<template v-slot:activator>
							<v-list-item-icon>
								<v-icon v-text="item.icon"></v-icon>
							</v-list-item-icon>
							<v-list-item-title v-text="item.text"></v-list-item-title>
						</template>
						<v-list-item v-for="sublink in item.sublinks" :key="sublink.text" :to="sublink.link" >
							<v-list-item-title v-text="sublink.text" style="font-size:10px;">
							</v-list-item-title>
						</v-list-item>
					</v-list-group>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>	
</template>

<script>
	// import axios from 'axios'
	// import { baseApiUrl, showError } from '@/global' 
	import { mapState } from 'vuex'
	import UserDropdown from './UserDropdown'
// import Notification from './Notification'

	export default {

		name: 'TheHeader',
	components: { UserDropdown, /*Notification*/ },
		props: {
			titulo: String,
			hideToggle: Boolean,
			hideUserDropdown: Boolean
		},
		data() {
			return {
				meta:0,
				drawer:true,
				items:[]
			}
		},
		computed:mapState(['user']),
		methods:{
			fnLink(){

				this.items = [
					{text:'Painel de Controle', icon:'mdi-home', link:'/'},
					{text:'Ordem de Serviço', icon:'mdi-account-wrench', link:'/ordem_servico'},
					{text:'Usuários', icon:'mdi-account-group', link:'/usuarios'},
					// {text:'Esteira', icon:'mdi-road-variant', link:'/esteira'},
					// {text:'Formalização', icon:'mdi-file-check', link:'/formalizacao'},
					// {text:'Contratos', icon:'mdi-file-document', link:'/contratos'},
					// {text:'Agendamentos', icon:'mdi-calendar-account', link:'/agendamentos'},
					// {text:'Aniversariantes', icon:'mdi-cake-variant', link:'/aniversariantes'},
					// {text:'Financeiro', icon:'mdi-currency-brl', sublinks:[
					// 	{text:'Bancos e Condições',  link:'/financeiro'},
					// 	{text:'Receitas e Despesas', link:'/receitas_despesas'},
					// 	]},
					// {text:'Metas', icon:'mdi-chart-bar', link:'/metas'},
					// {text:'Torpedo Wa', icon:'mdi-whatsapp', link:'/wa'},
					// {text:'Lixeira', icon:'mdi-delete', link:'/lixeira'},
					]


			}
		},
		mounted(){
			this.fnLink()

		},

	};
</script>

<style lang="css" scoped>

</style>
