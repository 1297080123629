<template>
	<v-container>
    <PageTitle icon="mdi mdi-home" main="Painel Principal" sub=""/>
  </v-container>
</template>

<script>
  import PageTitle from "@/templates/PageTitle"
  export default {

    name: 'TheHome',
    components: { PageTitle },
    data () {
      return {

      }
    }
  }
</script>

<style lang="css" scoped>
</style>