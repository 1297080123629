<template>
  <v-app>
    <Loading v-if="validatingToken"/>
    <Content v-else/>
    <Header v-if="user"/>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import { baseApiUrl, userKey } from '@/global'
  import { mapState } from 'vuex'
  import Content from '@/templates/Content'
  import Header from '@/templates/Header'
  import Loading from '@/templates/Loading'

  export default {
    name: 'App',
    components: { Content, Header, Loading },
    computed: mapState(['user']),
    data() {
      return {
        validatingToken:true
      }
    },
    methods: {
      async validateToken() {
        this.validatingToken = true

        const json = localStorage.getItem(userKey)
        const userData = JSON.parse(json)
        this.$store.commit('setUser', null)

        if(!userData) {
          this.validatingToken = false
          this.$router.push({ name: 'auth'}).catch(() => {})
          return
        }

        const res = await axios.post(`${baseApiUrl}/validateToken`, userData)
        if(res.data) {
          this.$store.commit('setUser', userData)
        } else {
          localStorage.removeItem(userKey)
          this.$router.push({ name: 'auth' }).catch(() => {})
        }
        this.validatingToken = false
      }
    },
    mounted() {
      this.validateToken()
    }
  };
</script>
