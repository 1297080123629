<template>
	<v-container>
		<PageTitle icon="mdi mdi-account-group" main="Usuários" sub=""/>
    <DialogUser ref="dialogUserRef" />
    <v-col>
      <v-data-table :headers="table" :items="users">
        <template v-slot:actions="{ item }">
          <v-btn icon small color="orange" @click="loadUser(item, 'save')"><v-icon small>mdi-pencil</v-icon></v-btn>
          <v-btn icon small color="error" @click="loadUser(item, 'remove')"><v-icon small>mdi-delete</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { baseApiUrl, showError } from '@/global'
  import DialogUser from './DialogUser'
  // import moment from 'moment'
  import PageTitle from '@/templates/PageTitle'	
  export default {

    name: 'TheUsuarios',
    components:{ PageTitle, DialogUser },
    data () {
      return {
        users:[],
        table:[
          { text:"Nome", value:"nome"},
          { text:"Email", value:"email"},
          { text:"Ações", value:"actions"},
          ]
      }
    },
    methods:{
     async loadUsers() {
      try {
        const res = await axios.get(`${baseApiUrl}/usuarios`)
        this.users = res.data;
      } catch (error) {
        showError(error);
      }
    },
    async loadUser(item, modo = 'save') {
      try{
        this.$refs.dialogUserRef.loadUser(item, modo)
      } catch(e){
        showError(e)
      }
    }
  },
  mounted(){
    this.loadUsers()
  }
}
</script>

<style lang="css" scoped>
</style>