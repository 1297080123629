<template>
	<v-dialog v-model="dialog" max-width="80%">
		<template v-slot:activator="{ on }">
			<v-col md="6" sm="12">
				<v-btn @click="loadUser('', 'save')" x-small color="#37474F" v-on="on" outlined>Novo usuário</v-btn>
			</v-col>
		</template>
		<v-card>
			<v-card-title>Cadastro de Usuário</v-card-title>
			<v-card-text>
				<!-- Seção de informações do entrevistador -->
				<v-flex row>
					<v-col cols="12" md="8">
						<v-text-field label="NOME:" v-model="user.nome"></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field label="EMAIL:" v-model="user.email"></v-text-field>
					</v-col>
				</v-flex>
				<!-- <v-flex row>
					<v-col cols="12" md="4">
						<v-text-field label="ENDEREÇO:" v-model="user.endereco"></v-text-field>
					</v-col>
					<v-col cols="12" md=4>
						<v-text-field label="CIDADE:" v-model="user.cidade"></v-text-field>
					</v-col>
				</v-flex>
				<v-flex row>
					<v-col cols="12" md="2">
						<v-text-field label="ESTADO:" v-model="user.uf_usuario"></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field label="PERMISSÃO:" v-model="user.permissao"></v-text-field>
					</v-col>
				</v-flex>
				<v-flex row>
					<v-col cols="12" md="4">
						<v-text-field label="SENHA:" v-model="user.senha_usuario"></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field label="CONFIRME SENHA:" v-model="user.confirme_senha_usuario"></v-text-field>
					</v-col>
				</v-flex> -->
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary">SALVAR</v-btn>
				<v-btn color="grey" @click="reset()">CANCELAR</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { showError } from '@/global'
	export default {

		name: 'DialogUser',
		data () {
			return {
				mode:'',
				user:{},
				dialog:false,
			}
		},
		methods:{
			async loadUser(item, modo = 'save') {
				try{
					this.modo = modo
					this.user = item ? { ...item } : {}
					this.dialog = true
				}catch(e){
					showError(e)
				}
			},
			async reset() {
				try{
					this.dialog = false
					this.user ={}
				}catch(e){
					showError(e)					
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>